<template>
  <div>
    <HeaderAdmin v-if="perfile_id === '1'" />
    <HeaderEjecutivo v-if="perfile_id === '2'" />
    <b-container>
      <b-row class="mt-3">
        <b-col>
          <h4 class="text-copec"> Cambiar Contraseña</h4>
        </b-col>
      </b-row>
      <div class="alert alert-danger" role="alert" v-if="error">
        {{ error_msg }}
      </div>
      <b-row class="mt-3">
        <b-col>
          <b-form v-on:submit.prevent="cambiarClave">
            <b-form-group id="passwordForm-group" label="Nueva Contraseña" label-for="passwordForm" class="mt-3">
              <b-form-input type="password" required name="passwordForm" id="passwordForm" v-model="passwordForm" minlength="6"></b-form-input>
            </b-form-group>
            <b-form-group id="passConfirmForm-group" label="Confirmar Nueva Contraseña" label-for="passConfirmForm" class="mt-3">
              <b-form-input type="password" required name="passConfirmForm" id="passConfirmForm" v-model="passConfirmForm" minlength="6"></b-form-input>
            </b-form-group>
            <b-row class="mt-5">
              <b-col>
                <b-button v-if="perfile_id == 1" v-bind:to="'/ejecutivo/Usuarios'"  variant=" m-1" class="btn btn-outline-copec ">Cancelar</b-button>
                <b-button v-if="perfile_id == 2" v-bind:to="'/ejecutivo/RevisarDocumentos'"  variant=" m-1" class="btn btn-outline-copec ">Cancelar</b-button>
                <b-button type="submit" class="btn-copec">
                  <div v-if="guardando" class="spinner-border spinner-border-sm"></div> Guardar
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
        <b-col offset="2">
          <h3>Datos del Usuario</h3>
          <div v-if="loading" class="spinner-border spinner-border-sm"></div>
          <div v-else>
            <table class="table">
              <tr>
                <th>Email</th>
                <td>{{ usuario.email }}</td>
              </tr>
              <tr>
                <th>Nombre</th>
                <td>{{ usuario.nombre }} {{ usuario.apellido }}</td>
              </tr>
              <tr>
                <th>Perfil</th>
                <td>{{ usuario.perfil_nombre }}</td>
              </tr>
              <tr>
                <th>Estado</th>
                <td>{{ (usuario.estado == 0) ? 'Inactivo' : 'Activo' }}</td>
              </tr>
            </table>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import HeaderAdmin from '@/components/HeaderAdmin.vue';
import HeaderEjecutivo from '@/components/HeaderEjecutivo.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';

let json = {};

export default {

  components: {
    HeaderAdmin,
    HeaderEjecutivo,
    Footer,
  },

  data() {
    return {
      loading        : true,
      guardando      : false,
      passwordForm   : null,
      passConfirmForm: null,
      error          : false,
      erro_msg       : '',
      usuario        : {
        email: '',
        nombre: '',
        apellido: '',
        perfil_nombre: '',
        estado: '',

      },
      perfile_id: this.$cookies.get('perfile_id')
    }
  },
  mounted:function() {
    if(this.$cookies.get('token') === null){
       this.$router.push('/ejecutivo/iniciarsesion')
    }
    // llama al servicio que obtiene los usuarios
    axios
    .post(process.env.VUE_APP_API_URL_BASE + 'api/ejecutivo/obtener-usuario?id=' + this.$route.params.id + '&token=' + this.$cookies.get('token'),{data:{}},{
        headers:{
          'Content-Type': 'application/json'
        }
      })
    .then((data) => {

      // desactiva el loading
      this.loading = false;

      // si llega success
      if (data.data.status === 'success') {

        // guarda en los usuarios
        this.usuario = data.data.data;

      } else { // llega un error, marca error
        this.error     = true;
        this.error_msg = data.data.data;
      }
    }).catch((error) => {
        this.sessionKill(error)
    });
  },

  methods: {

    // crea al usuario en la base de datos
    cambiarClave() {

      // activa el spinner del loading en el boton
      this.guardando = true;

      // valida si las contraseñas coinciden
      if (this.passwordForm === this.passConfirmForm) {

        // obtiene la observación del formulario
        json = {
          'id'         : this.$route.params.id,
          'password'   : this.passwordForm,
          'passConfirm': this.passConfirmForm
        };

        // realiza la consulta al servicio
        axios
        .post(process.env.VUE_APP_API_URL_BASE + 'api/ejecutivo/editar-clave-usuario?token=' + this.$cookies.get('token'), json)
        .then((data) => {

          // activa el loading
          this.guardando = true;

          // si llega status success, ok
          if (data.data.status === 'success') {

            // si es ejecutivo, redirecciona al sitio de los usuarios
            if (this.$cookies.get('perfile_id') === '1') {
              this.$router.push('/ejecutivo/usuarios');
            }

            // si es ejecutivo, redirecciona al sitio de los documentos
            if (this.$cookies.get('perfile_id') === '2') {
              this.$router.push('/ejecutivo/revisardocumentos');
            }

          } else { // hay error
            this.error     = true;
            this.error_msg = data.data.data;

            // desactiva el spinner del loading en el boton
            this.guardando = false;
          }
        }).catch((error) => {
            this.sessionKill(error)
      });

      } else { // contraseñas no coinciden

        // mensaje de error
        this.error     = true;
        this.error_msg = "Las contraseñas no coinciden, intente nuevamente"

        // desactiva el spinner del loading en el boton
        this.guardando = false;
      }
    },
    sessionKill(){
      document.getElementById('killsession').click();
    }
  },
};
</script>

<style scoped>
th {
  width: 100px;
}
</style>

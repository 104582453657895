<template>
  <div class="home">
    <div class="wrapper fadeInDown">
      <div id="formContent">
        <div class="fadeIn first">
          <img src="@/assets/logo_copec.svg" id="icon" alt="User Icon" />
        </div>
        <div class="p-2">
          <p>Ingrese el código que llegó a su Email para ingresar al Sistema</p>
        </div>
        <form v-on:submit.prevent="login">
          <input type="text" required id="login" class="fadeIn second" name="login" placeholder="Código" v-model="codigo" />
          <div v-if="!loading" class="mt-2 mb-5 text-primary">
            <b-button v-bind:to="'/'" variant=" m-1" class="btn btn-outline-copec mx-3" size="lg">Volver Atrás</b-button>
            <b-button type="submit" class="btn-copec mx-3" size="lg" >
              Ingresar
            </b-button>
          </div>
          <div v-if="loading" class="mt-2 mb-5 text-primary">
           <b-button v-bind:to="'/'" variant="light" size="lg" class="mx-3">Volver Atrás</b-button>
           <button class="btn btn-primary btn-lg mx-3" disabled><div class="spinner-border spinner-border-sm"></div> Ingresando</button>
          </div>
        </form>
        <div class="alert alert-danger mx-3" role="alert" v-if="error">
          {{ error_msg }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Home',
  components: {},
  data: function () {
    return {
      codigo: '',
      error: false,
      erro_msg: '',
      loading: false
    };
  },

  mounted:function(){

    // coloca el código en el formulario
    this.codigo = this.$route.query.codigo;

    // si viene el rut pasa a la cookie
    if (typeof this.$route.query.rut !== 'undefined') {
      this.$cookies.set('rut', this.$route.query.rut);
    }
  },

  methods: {
    login() {

      // activa el loading
      this.loading = true;

      // json para pasar al post
      let json = {
        rut: this.$cookies.get('rut'), // cookie
        codigo: this.codigo, // formulario
      };

      // realiza la consulta al servicio
      axios
      .post(process.env.VUE_APP_API_URL_BASE + 'api/cliente/obtener-sesion', json)
      .then((data) => {

        // si llega status success, ok
        if (data.data.status === 'success') {
          if(data.data.data.terminos !== false){
              this.$swal({
              allowOutsideClick: false,
              title:'Términos y Condiciones',
              html: data.data.data.terminos,
              width: '800px',
              showCancelButton: true,
              confirmButtonColor: '#41b24c',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Aceptar',
              cancelButtonText: 'Rechazar'
            }).then((result) => {
              if (result.isConfirmed) {
                this.$cookies.set('token', data.data.data.token);
                this.$cookies.set('nombre', data.data.data.nombre);
                this.$router.push('MisDocumentos');
              }else{
                this.$router.push('/');
              }
            })
          }else{
            this.$cookies.set('token', data.data.data.token);
            this.$cookies.set('nombre', data.data.data.nombre);
            this.$router.push('MisDocumentos');
          }

        } else { // hay error
          this.error = true;
          this.error_msg = data.data.data;
            this.loading = false;
        }
      }).catch((error) => {
          if(error.response.status === 400){
              this.$swal({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Ha ocurrido un error por favor reintente nuevamente'
              })
          }
      });
    },
  },
};
</script>

<style scoped>
html {
  background-color: #56baed;
}

body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

a {
  color: #92badd;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  margin: 40px 8px 10px 8px;
  color: #cccccc;
}

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/

input[type="button"],
input[type="submit"],
input[type="reset"] {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover {
  background-color: #39ace7;
}

input[type="button"]:active,
input[type="submit"]:active,
input[type="reset"]:active {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

input[type="text"] {
  background-color: #f6f6f6;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type="text"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type="text"]:placeholder {
  color: #cccccc;
}

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 40%;
  margin: 50px;
}
</style>

<template>
  <div class="mt-3 sombra">
    <b-navbar toggleable="md" type="light" variant="white">
      <b-container>
        <b-navbar-brand>
          <img src="@/assets/logo_copec.svg" id="logo" alt="Logo Copec"/>
        </b-navbar-brand>
        <b-navbar-brand>
          <div class="alert alert-info" role="alert" v-if="enviroment == 'TESTING'">Se encuentra en el entorno de TESTING </div>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item v-bind:to="'MisDocumentos'"  class="m-1"><span id="text_doc" > Mis Documentos</span></b-nav-item>
            <b-nav-item v-bind:to="'Subirdocumentos'"  class="m-1"><span id="text_up_doc" > Subir Documento</span></b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown right>
              <!-- Using 'button-content' slot -->
              <template #button-content>
                <em><b-icon icon="person-circle"></b-icon>{{ rut }}</em>
              </template>
              <div class="nombre-cliente">{{ nombre }}</div>
              <b-dropdown-item v-on:click.prevent="logout()"><b-icon icon="box-arrow-right"></b-icon> Salir</b-dropdown-item>
              <b-dropdown-item style="display:none" id="killsession" v-on:click.prevent="killSessionCliente()"><b-icon icon="box-arrow-right"></b-icon> killSession</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
export default ({

  data: function() {
    return {
      rut: this.$cookies.get('rut'),
      nombre: this.$cookies.get('nombre'),
      enviroment: process.env.ENVIROMENT
    };
  },

  methods: {
    logout() {
      this.$cookies.remove('rut');
      this.$cookies.remove('email');
      this.$cookies.remove('nombre');
      this.$cookies.remove('token');
      this.$router.push('/');
    }
  }
});
</script>

<style scoped>
* {
  font-size: 1rem;
  color: #7C97AB;
}
.ml-auto {
  margin-left: auto;
}
.navbar.navbar-dark.bg-dark {
  background-color: #aabb55 !important;
}
#logo {
  width: 6rem;
}

.nombre-cliente {
  margin: 10px;
  font-size: 0.8rem;
  border-bottom: solid #ecf6fd 1px;
  padding-bottom: 12px;
}
</style>

<template>
  <div>
    <HeaderAdmin />
    <b-container>
      <b-row class="mt-3">
        <b-col>
          <h3 class="text-copec"> Usuarios</h3>
        </b-col>
        <b-col class="text-right">
          <b-button class="btn btn-copec btn-sm " v-bind:to="'CrearNuevoUsuario'">+ Crear nuevo usuario</b-button>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <table class="table bg-white">
            <thead>
              <tr>
                <th id="h-email">Email</th>
                <th id="h-perfil">Perfil</th>
                <th id="h-nombre">Nombre</th>
                <th id="h-estado">Estado</th>
                <th id="h-creado">Creado</th>
                <th id="h-acciones" class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(usuario, index) in usuarios" v-bind:key="index">
                <td>{{ usuario.email }}</td>
                <td>{{ usuario.perfil }}</td>
                <td>{{ usuario.nombre }} {{ usuario.apellido }}</td>
                <td>{{ (usuario.estado == 0) ? 'Inactivo' : 'Activo'  }}</td>
                <td>{{ usuario.creado | luxon('yyyy-MM-dd HH:mm') }}</td>
                <td class="text-center text-nowrap">
                  <b-button size="sm" variant="outline-info" title="Cambiar Contraseña" v-on:click="cambiarClave(usuario.id)"><b-icon icon="lock"></b-icon> Contraseña</b-button>&nbsp;
                  <b-button size="sm" variant="outline-info" title="Editar Usuario" v-on:click="editarUsuario(usuario.id)"><b-icon icon="pencil-square"></b-icon> Editar</b-button>&nbsp;
                  <b-button size="sm" variant="outline-danger" title="Eliminar Usuario" v-on:click="eliminarUsuario(usuario.id)"><b-icon icon="trash"></b-icon> Eliminar</b-button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="cargando" class="text-center">
            <div class="spinner-border"></div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import HeaderAdmin from '@/components/HeaderAdmin.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';

export default {
  components: {
    HeaderAdmin,
    Footer,
  },

  mounted:function() {
    if(this.$cookies.get('token') === null){
       this.$router.push('/ejecutivo/iniciarsesion')
    }
    document.getElementById('tc').classList.remove('text-copec')
    document.getElementById('text_user').classList.add('text-copec')
    this.obtener_usuario()
  },

  data: function ()
  {
    return {
      usuarios: null,
      error   : false,
      erro_msg: '',
      cargando : true
    };
  },

  methods: {
    // redirige al sitio de cambio de clave del usuario
    cambiarClave(id) {
      this.$router.push('/ejecutivo/cambiarclave/' + id);
    },
    // redirige al sitio de edicion de usuario
    editarUsuario(id) {
      this.$router.push('/ejecutivo/editarusuario/' + id);
    },
    async obtener_usuario() {

      // llama al servicio que obtiene los usuarios
      await  axios
      .post(process.env.VUE_APP_API_URL_BASE + 'api/ejecutivo/listado-usuarios?token=' + this.$cookies.get('token'),{data:{}},{
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((data) => {
        // desactiva el cargando
        this.cargando = false;
        // si llega success
        if (data.data.status === 'success') {

          // guarda en los usuarios
          this.usuarios = data.data.data;
        } else { // llega un error, marca error
          this.error     = true;
          this.error_msg = data.data.data;
        }
      }).catch((error) => {
        this.sessionKill(error)
      });
    },
    // rechaza el documento
    eliminarUsuario(id) {
      // activa el cargando
      this.cargando = true;
      // alerta swwet
      this.$swal({
        title             : 'Eliminar Usuario',
        text              : 'Esto eliminará de manera al usuario. ¿Está seguro que desea eliminar a este usuario?',
        showCancelButton  : true,
        cancelButtonText  : 'Cancelar',
        confirmButtonColor: '#dc3545',
        confirmButtonText : '¡Sí, Eliminar!',
      }).then((result) => {
        // si confirma
        if (result.value) {
          // obtiene el id del usuario
          const json = {
            usuarioId: id
          };
          // llama al servicio
          axios
          .post(process.env.VUE_APP_API_URL_BASE + 'api/ejecutivo/eliminar-usuario?token=' + this.$cookies.get('token'), json)
          .then((data) => {
            // si llega success
            if (data.data.status === 'success') {
              // redirecciona al mismo sitio
              this.$router.go(this.$router.currentRoute);
            } else { // llega un error, marca error
              this.error = true;
              this.error_msg = data.data.data;
              // desactiva el cargando
              this.cargando = false;
            }
          }).catch((error) => {
            this.sessionKill(error)
        });
        } else { // cancela
          // se desactiva el cargando
          this.cargando = false;
        }
      });
    },
    sessionKill(){
      document.getElementById('killsession').click();
    }
  }
};
</script>

<style scoped>
.comentario{
  max-width: 300px;
}

.text-deshabilitado {
  color: #BBBBBB;
}

table {
  font-size: small;
}

td {
  vertical-align: middle;
}

.manito {
  cursor: pointer;
}
</style>

<template>
  <div>
    <HeaderEjecutivo />
    <b-container>
      <b-row class="mt-3">
        <b-col>
          <h4>Ver Documento</h4>
        </b-col>
      </b-row>
      <div class="alert alert-danger" role="alert" v-if="error">
        {{ error_msg }}
      </div>
      <div v-if="error" class="text-center">
        <b-button v-bind:to="'/ejecutivo/iniciarsesion?documento=' + this.$route.params.id" variant="outline-primary" size="lg">Ir a Iniciar Sesión</b-button>
      </div>
      <b-row class="mt-3"  v-if="!error">
        <b-col>
          <table class="table bg-white" v-if="documento">
            <caption>Documento</caption>
            <thead>
              <tr>
                <th id="h-empresa">Empresa</th>
                <th id="h-fecha" class="text-center">Fecha</th>
                <th id="h-estado" class="text-center">Estado</th>
                <th id="h-documentos" class="text-center">Documentos</th>
                <th id="h-notas" class="comentario">Notas</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ documento.cliente_nombre }}<br>
                  <small>{{ documento.cliente_rut }}</small>
                </td>
                <td class="text-nowrap text-center">
                  {{ documento.creacion | luxon('dd-MM-yyyy') }} <br />
                  <small>{{ documento.creacion | luxon('dd-MM-yyyy HH:mm') }}</small>
                </td>
                <td class="text-nowrap text-center">
                  <span v-if="documento.estado_id == 1"><b-icon icon="hourglass" scale="1.5"></b-icon><br>{{ documento.estado }}</span>
                  <span v-if="documento.estado_id == 2" class="text-danger"><b-icon icon="x-circle-fill" scale="1.5"></b-icon><br>{{ documento.estado }}</span>
                  <span v-if="documento.estado_id == 3" class="text-success"><b-icon icon="check-circle-fill" scale="1.5"></b-icon><br>{{ documento.estado }}</span>
                </td>
                <td class="text-nowrap text-center celda-documentos">
                  <b-row>
                    <b-col
                      v-bind:title="documento.documento_floid"
                      v-bind:class="[documento.documento_floid ? 'manito' : 'text-deshabilitado']"
                      v-on:click.prevent="[documento.documento_floid ? descargar(documento.id, 'documento_floid') : '']"
                      class="text-center"
                    >
                      <b-row><b-col><b-icon icon="file-earmark-text" scale="1.5"></b-icon></b-col></b-row>
                      <b-row><b-col class="texto-doc">Documento floid</b-col></b-row>
                    </b-col>
                    <b-col
                      v-bind:title="documento.balance"
                      v-bind:class="[documento.balance ? 'manito' : 'text-deshabilitado']"
                      v-on:click.prevent="[documento.balance ? descargar(documento.id, 'balance') : '']"
                      class="text-center"
                    >
                      <b-row><b-col><b-icon icon="file-earmark-text" scale="1.5"></b-icon></b-col></b-row>
                      <b-row><b-col class="texto-doc">Balance</b-col></b-row>
                    </b-col>
                    <b-col
                      v-bind:title="documento.estado_de_resultados"
                      v-bind:class="[documento.estado_de_resultados ? 'manito' : 'text-deshabilitado']"
                      v-on:click.prevent="[documento.estado_de_resultados ? descargar(documento.id, 'estado_de_resultados') : '']"
                      class="text-center"
                    >
                      <b-row><b-col><b-icon icon="file-earmark-text" scale="1.5"></b-icon></b-col></b-row>
                      <b-row><b-col class="texto-doc">Estado de<br>Resultados</b-col></b-row>
                    </b-col>
                    <b-col
                      v-bind:title="documento.carpeta_tributaria"
                      v-bind:class="[documento.carpeta_tributaria ? 'manito' : 'text-deshabilitado']"
                      v-on:click.prevent="[documento.carpeta_tributaria ? descargar(documento.id, 'carpeta_tributaria') : '']"
                      class="text-center"
                    >
                      <b-row><b-col><b-icon icon="file-earmark-text" scale="1.5"></b-icon></b-col></b-row>
                      <b-row><b-col class="texto-doc">Carpeta<br>Tributaria</b-col></b-row>
                    </b-col>
                    <b-col
                      v-bind:title="documento.otro_documento"
                      v-bind:class="[documento.otro_documento ? 'manito' : 'text-deshabilitado']"
                      v-on:click.prevent="[documento.otro_documento ? descargar(documento.id, 'otro_documento') : '']"
                      class="text-center"
                    >
                      <b-row><b-col><b-icon icon="file-earmark-text" scale="1.5"></b-icon></b-col></b-row>
                      <b-row><b-col class="texto-doc">Otro<br>Documento</b-col></b-row>
                    </b-col>
                  </b-row>
                </td>
                <td class="comentario">
                  <b-row v-if="documento.observacion">
                    <b-col><strong>Observación:</strong> {{ documento.observacion }}</b-col>
                  </b-row>
                  <b-row v-if="documento.fecha_revision">
                    <b-col>
                      <strong>Revisión {{ documento.fecha_revision | luxon('dd-MM-yyyy HH:mm') }}<span v-if="documento.mensaje_revision">:</span></strong> {{ documento.mensaje_revision }}
                    </b-col>
                  </b-row>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="cargando || ! documento" class="text-center">
            <div class="spinner-border"></div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import HeaderEjecutivo from '@/components/HeaderEjecutivo.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';


export default {

  components: {
    HeaderEjecutivo,
    Footer,
  },
  mounted:function() {
    // llama al servicio que obtiene los documentos
    this.get_documento();
  },
  data: function ()
  {
    return {
      documento: null,
      error    : false,
      erro_msg : '',
      loading  : false,
      cargando : true
    };
  },
  methods: {
    get_documento(){
      axios.post(process.env.VUE_APP_API_URL_BASE + 'api/ejecutivo/ver-documentos?token=' + this.$cookies.get('token') + '&id=' + this.$route.params.id,{data:{}},{
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((data) => {
        // desactiva loading del sitio
        this.cargando = false;
        // si llega success
        if (data.data.status === 'success') {
          // guarda los documentos
          this.documento = data.data.data[0];
        } else { // llega un error, marca error
          this.error     = true;
          this.error_msg = data.data.data;
        }
      }).catch((error) => {
       this.sessionKill(error);
      });
    },
    descargar(documento_id, tipo) {
      let token = this.$cookies.get('token');
      let url = process.env.VUE_APP_API_URL_BASE + 'api/ejecutivo/descargar-documento/' + documento_id + '?tipo=' + tipo + '&token=' + token;
      this.$swal({
          title: 'Obteniendo documento ',
          allowOutsideClick: false,
          didOpen: () => {
              this.$swal.showLoading()
          },
      })
      axios.post(url,{data:{}},{
        headers:{
          'Content-Type': 'application/json'
        }
      }).then((data) => {
        // desactiva loading del sitio
        if(data.data.status === 'success'){
          this.$swal.close()
          window.open(data.data.url);
        }else{
          this.$swal({
            icon: 'info',
            title: 'No fue posible obtener el documento'
          })
        }
      }).catch((error) => {
           this.sessionKill(error)
        });
    },
    sessionKill(){
      document.getElementById('killsession').click();
    }
  }
};
</script>

<style scoped>
.comentario{
  max-width: 300px;
}

.text-deshabilitado {
  color: #BBBBBB;
}

table {
  font-size: small;
}

td {
  vertical-align: middle;
}

.manito {
  cursor: pointer;
}

.celda-documentos {
  padding-left: 40px;
  padding-right: 40px;
}

#h-documentos {
 min-width: 400px
}

.texto-doc {
    line-height: 1rem;
    margin-top: 0.3rem;
}

</style>

<template>
  <div>
    <HeaderCliente />
    <b-container class="bg-white" >
      <b-row class="mt-3 centrado">
        <div class="p-2">
          <p>
            Ingrese el RUT de su Empresa para solicitar un código de
            acceso al Sistema, con esto esta enviado a copec toda la información de su carpeta financiera
          </p>
        </div>
        <div class="alert alert-danger" id="errorform" role="alert" v-if="error">
          {{ error_msg }}
        </div>
        <div class="formulario">
            <div class="form-group mt-3">
              <input type="radio"   class="form-check-input" name="tipoentrada" data-name="tipoentrada"  id="tipoentrada" value="Clave_Unica" checked/>
              <strong style="padding-left:5px"> Clave Unica</strong>
            </div>
            <div class="form-group mt-3">
              <input type="radio"   class="form-check-input" name="tipoentrada" data-name="tipoentrada"  id="tipoentrada" value="SII" />
              <strong style="padding-left:5px"> SII</strong>
            </div>
            <div class="form-group mt-3">
              <strong> Rut</strong><br>
              <input type="text"  class="form-control" name="rutempresa" data-name="rutempresa"  id="rutempresa" ref="rutempresa" />
            </div>
            <div class="form-group mt-3">
              <strong> Contraseña</strong><br>
              <input type="password"  class="form-control" name="password" data-name="password"  id="password" ref="password" />
            </div>
            <b-row class="mt-3">
              <b-col>
                <b-button  variant=" m-1" class="btn btn-outline-copec ">Cancelar</b-button>
                <b-button v-if="!loading" type="submit" class="btn-copec" v-on:click="Conectarse()" >
                  Conectarse
                </b-button>
              </b-col>
            </b-row>
        </div>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import HeaderCliente from '@/components/HeaderCliente.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';

// datos del formulario
//let fd = new FormData();

export default {
  components: {
    HeaderCliente,
    Footer,
  },

  data() {
    return {
      isUploading: false,
      idDocumento: null,
      loading: false,
      observacionForm: '',
      error: false,
      error_msg: false,
      tamagnoMaximoMb: 3,
      archivosPermitidos: [
        'application/pdf',
        'image/png',
        'image/jpeg',
        'image/gif'
        ],
      archivosPermitidosHumano: ['pdf', 'png', 'jpeg', 'jpg', 'gif']
    }
  },

  mounted:function(){

  },

  methods: {
    // se coloca un documento en el formulario
    // guarda el documento
    Conectarse() {
      // alerta
      this.$swal({
        title             : 'Conexion FLOID',
        text              : 'Esta accion enviara sus credenciales para generar el documento financiero DESDE FLOID, ¿Desea Aceptar?',
        showCancelButton  : true,
        cancelButtonText  : 'Cancelar',
        cancelButtonColor :  "#d33",
        confirmButtonColor: '#0d6efd',
        confirmButtonText : '¡Sí, Aceptar!'
      }).then((result) => {
        // si confirma
        if (result.value) {
          let rutempresa = document.getElementById('rutempresa').value;
          let password = document.getElementById('password').value;
          let tipoentrada = Array.from(document.getElementsByName("tipoentrada")).find(r => r.checked).value;
          this.procesardatos(rutempresa,password,tipoentrada)
        }
      });
    },
  async procesardatos(rutempresa,password,tipoentrada){
    let json = {
      "rutempresa": rutempresa,
      "password": password,
      "tipoentrada": tipoentrada,
      "token" : this.$cookies.get('token')
    }

    this.$swal({
          title: 'Obteniendo datos floid...',
          allowOutsideClick: false,
          didOpen: () => {
              this.$swal.showLoading()
          },
    })

    try {
        await axios.post(process.env.VUE_APP_API_URL_BASE +'api/cliente/conexionfloid?token=' +this.$cookies.get('token'), json,{
        headers:{
          'Content-Type': 'application/json'
        }
      })
      let timerInterval
      this.$swal({
        title  : 'Conexion FLOID',
        text   : 'Se cargaron documentos en copec exitosamente',
        timer: 5000,
        timerProgressBar: true,
        footer:"En unos segundos serás redireccionado.",
        didOpen: () => {
          this.$swal.showLoading()
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
      }).then((result) => {
      if (result.dismiss === this.$swal.DismissReason.timer){
        this.$router.push('MisDocumentos')
      }  
    })

    } catch (error) {
      let timerInterval
      this.$swal({
        title  : 'Conexion FLOID',
        text   : 'Error al cargar los datos a copec.',
        timer: 5000,
        timerProgressBar: true,
        footer:"En unos segundos serás redireccionado.",
        didOpen: () => {
          this.$swal.showLoading()
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
      }).then((result) => {
      if (result.dismiss === this.$swal.DismissReason.timer){
       this.$router.push('MisDocumentos')
      }
    })
    }


  }
  //fin funciones
  },
};



</script>

<template>
  <div class="home">
    <div class="wrapper fadeInDown">
      <div id="formContent">
        <div class="fadeIn first">
          <img src="@/assets/logo_copec.svg" id="icon" alt="User Icon" />
        </div>
        <form v-on:submit.prevent="login">
          <input
            type="text" required id="email" class="fadeIn second" name="email" placeholder="Email" v-model="email"/>
          <input type="password" required id="password" class="fadeIn third" name="password" placeholder="Password" v-model="password" />
          <b-button type="submit" size="lg" class=" btn-copec mt-4 mb-5">
            <div v-if="loading" class="spinner-border spinner-border-sm"></div> Iniciar Sesión
          </b-button>
        </form>
        <div class="alert alert-danger" role="alert" v-if="error">
          {{ error_msg }}
        </div>
      </div>
        <b-link class="mt-5" v-bind:to="'/'" variant="link">Login Cliente</b-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "Login",
  components: {},
  data: function () {
    return {
      email   : '',
      password: '',
      error   : false,
      erro_msg: '',
      loading : false
    };
  },
  methods: {

    // hace el login
    login() {

      // activa el spinner del loading
      this.loading = true;

      // valores que se van a pasar por post
      let json = {
        email   : this.email,
        password: this.password,
      };

      // llamada al servicio para iniciar sesión
      axios
      .post(process.env.VUE_APP_API_URL_BASE + 'api/ejecutivo/iniciar-sesion', json)
      .then((data) => {

        // si llega success
        if (data.data.status === 'success') {

          // guarda en las cookies el token y el email
          this.$cookies.set('token', data.data.data.token);
          this.$cookies.set('email', data.data.data.email);
          this.$cookies.set('id', data.data.data.id);
          this.$cookies.set('perfile_id', data.data.data.perfile_id);

          // redirecciona al Lista de Usuarios si el perfil es Admin (1)
          if (parseInt(data.data.data.perfile_id) === 1) {
            this.$router.push('usuarios');
          }

          // redirecciona a Revisar Documentos si el perfil es Ejecutivo (2)
          if (parseInt(data.data.data.perfile_id) === 2) {

            // si viene el parametro documento en la url
            if (typeof this.$route.query.documento !== 'undefined') {

              // redirecciona a ver el documento
              this.$router.push('/documentos/' + this.$route.query.documento);

            } else {

              // redirecciona al sitio de revisión de documentos
              this.$router.push('revisardocumentos');
            }

          }

          // hay error con el perfil y no redirecciona
          this.error     = true;
          this.error_msg = 'Hay un error con el perfil del usuario';


        } else { // llega un error, marca error
          this.error     = true;
          this.error_msg = data.data.data;
        }

        // desactiva el spinner del loading
        this.loading = false;
      }).catch((error) => {
        if(error.response.status === 400){
            this.$swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Ha ocurrido un error por favor reintente nuevamente'
            })
        }
    });
    },
  },
};
</script>

<style scoped>
html {
  background-color: #56baed;
}

body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

a {
  color: #92badd;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  margin: 40px 8px 10px 8px;
  color: #cccccc;
}

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 200px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/

input[type="text"], input[type="password"] {
  background-color: #f6f6f6;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type="text"]:focus, input[type="password"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type="text"]:placeholder, input[type="password"]:placeholder {
  color: #cccccc;
}

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 40%;
  margin: 50px;
}
</style>

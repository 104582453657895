import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: '99ec2c65-6a13-43f6-9f4c-7a4ec83de969',
    clientToken: 'pub7867bbe8ecdb96e3f59cf26333da98ef',
    site: 'datadoghq.com',
    service:'link-financiero',
    env:'prd',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel:'mask-user-input'
});

datadogRum.startSessionReplayRecording();

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueAxios from 'vue-axios'
import Axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2';
import VueCookies from 'vue-cookies';
import VueLuxon from "vue-luxon";

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueAxios, Axios)
Vue.use(require('vue-moment'));
Vue.use(VueSweetalert2);
Vue.use(VueCookies);
Vue.$cookies.config('30d');
Vue.use(VueLuxon, {
  output: {
    zone: 'UTC'
  }
});

Vue.mixin({

  methods: {

    // cierra la sesión matando las cookies
    logout() {
      this.$cookies.remove('rut');
      this.$cookies.remove('email');
      this.$cookies.remove('token');
      this.$router.push('/ejecutivo/iniciarsesion');
    },
    killSessionEjecutivo(){
      this.swalKillSession('/ejecutivo/iniciarsesion')
    },
    killSessionCliente(){
      this.swalKillSession('/')
    },
    swalKillSession(ruta){
      let timerInterval
        this.$swal({
            icon: 'info',
            title: 'Tu sesión ha expirado',
            allowOutsideClick: false,
            timer: 5000,
            timerProgressBar: true,
            footer:"En unos segundos serás redireccionado.",
        didOpen: () => {
            this.$swal.showLoading()
        },
        willClose: () => {
            clearInterval(timerInterval)
        }
        }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === this.$swal.DismissReason.timer) {
            this.$router.push(ruta)
        }
        })

        setTimeout(()=>{
            this.$router.push(ruta)
        },9000);
    },
    // redirige al sitio de cambio de clave del usuario
    cambiarClave(id) {
      this.$router.push('/ejecutivo/cambiarclave/' + id);
    },

    habilitarInputs() {
      let elemsInput = document.getElementsByTagName('input');
      for(let i = 0; i < elemsInput.length; i++) {
        elemsInput[i].disabled = false;
      }
    },

    deshabilitarInputs() {
      let elemsInput = document.getElementsByTagName('input');
      for(let i = 0; i < elemsInput.length; i++) {
        elemsInput[i].disabled = true;
      }
    },

    paramsLangDatepicker() {
      return {
        formatLocale: {
          firstDayOfWeek: 1,
          months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
          weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
          weekdaysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
        },
        monthBeforeYear: false,
      };
    }

  },
})

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'sweetalert2/dist/sweetalert2.min.css';

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

<template>
  <div>
    <HeaderAdmin />
    <b-container>
         <div v-if="cargando" class="text-center mt-4">
            <div class="spinner-border"></div>
        </div>
        <div v-else>
            <div v-if="div_descripcion" id="descripcion" class="mt-3">
                <b-row>
                    <b-col >
                        <h5 class="mt-4 text-left text-copec ">{{tituloHtlm}}</h5>
                    </b-col>
                    <b-col  class="text-right" v-if="btn_edit">
                        <b-button  variant=" mt-3 m-1" class="btn btn-outline-copec p-1 btn-sm" v-on:click="viewCondiciones()" >Vista previa</b-button>
                        <b-button  variant=" mt-3 m-1"  class="btn btn-copec p-1 btn-sm" v-on:click="viewEditar()" >Editar</b-button>
                    </b-col>
                    <b-col cols="12">
                        <hr class="solid" >
                        <h6>Términos y condiciones permite crear un modal de texto que aparecerá luego de que el proveedor solicite su contraseña de validación a modo de paso intermedio</h6>
                    </b-col>
                </b-row>
            </div>
            <div v-if="div_formulario" id="formulario">
            <h5 class="mt-4 text-copec " >{{tituloForm}}</h5>
            <b-row class="mt-3">
                <b-col cols="12">
                    <b-form v-on:submit.prevent="crearEditarTerminos">
                    <b-form-group id="tituloForm-group" label="Título" label-for="tituloForm" class="mt-3">
                        <b-form-input class="form-control-file" name="tituloform" id="tituloform" v-model="tituloform" required placeholder="Ingresar título"></b-form-input>
                    </b-form-group>
                        <vue-editor
                            id="editor"
                            useCustomImageHandler
                            :editorToolbar="customToolbar"
                            v-model="htmlForEditor"
                            class="mt-4"
                            >
                        </vue-editor>
                    <div class="text-right">
                      <b-button  v-on:click="viewCondiciones()"  variant="link">Previsualizar modal</b-button>
                    </div>
                    <hr class="solid">
                    <b-col cols="12" class="mt-4 text-right">
                        <div>
                            <b-button v-on:click="volver()" v-if="btn_edit" variant=" m-1" class="btn btn-outline-copec ">Volver</b-button>
                            <b-button type="submit" class="btn-copec  m-1" >Guardar</b-button>
                        </div>
                    </b-col>
                    </b-form>
                </b-col>
            </b-row>
            </div>
        </div>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import HeaderAdmin from '@/components/HeaderAdmin.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';
import { VueEditor } from "vue2-editor";

let json = {};

export default {
  components: {
    HeaderAdmin,
    Footer,
    VueEditor
  },
  mounted:function() {
    // llama al servicio que obtiene los usuarios
    if(this.$cookies.get('token') === null){
       this.$router.push('/ejecutivo/iniciarsesion')
    }
    document.getElementById('text_user').classList.remove('text-copec')
    document.getElementById('tc').classList.add('text-copec')
    this.$swal.close()
    axios
    .post(process.env.VUE_APP_API_URL_BASE + 'api/getterminocondiciones?token=' + this.$cookies.get('token'),{data:{}},{
        headers:{
          'Content-Type': 'application/json'
        }
      })
    .then((data) => {
        let response = data.data
        this.cargando = false;
        if (response.status === 'success') {
            this.btn_edit = true
            this.id_condiciones = response.data.id
            this.titulo = response.data.titulo
            this.condiciones = response.data.condiciones
            this.tituloform = response.data.titulo
            this.htmlForEditor = response.data.condiciones
            this.div_formulario = false
        }else if(response.status === 'info'){
            this.div_formulario = true
        }else{
           this.$swal({
                icon: 'info',
                title: 'Oops...',
                text: 'No fue posible obtener los datos'
            })
        }
    }).catch((error) => {
        this.sessionKill(error)
    });
  },
  data() {
    return {
        btn_edit :false,
        div_descripcion:true,
        div_formulario:false,
        cargando : true,
        loading: false,
        id_condiciones: 0,
        condiciones:null,
        titulo:null,
        tituloform: null,
        htmlForEditor: null,
        customToolbar:  [
            [{ 'font': [] }],
            [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
            [{ 'header': 1 }, { 'header': 2 }],
            ['blockquote', 'code-block'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'color': [] }, { 'background': [] }],
            ['link'],
            [{ 'direction': 'rtl' }],
            ['clean'],
        ],
    tituloHtlm:"TÉRMINOS Y CONDICIONES",
    tituloForm:"CREAR TÉRMINOS Y CONDICIONES"
    }
  },
  methods: {
    async crearEditarTerminos() {
        this.$swal({
            title: 'Ingresando datos',
            allowOutsideClick: false,
            didOpen: () => {
                this.$swal.showLoading()
            },
        })
        json = {
            "titulo":this.tituloform,
            "contenido":this.htmlForEditor
        };
        let data;
        if(this.id_condiciones >= 1){
            json['id'] = this.id_condiciones
            data = await axios.put(process.env.VUE_APP_API_URL_BASE + 'api/terminocondiciones?token=' + this.$cookies.get('token'), json)
        .catch((error) => {
            this.sessionKill(error)
        });
        }else{
            data = await axios.post(process.env.VUE_APP_API_URL_BASE + 'api/terminocondiciones?token=' + this.$cookies.get('token'), json)
            .catch((error) => {
                this.sessionKill(error)
            });
        }
        if (data.data.status === 'success') {
            this.btn_edit = true
            this.loading = false;
            this.id = data.data.data.id
            this.titulo = this.tituloform
            this.condiciones =this.htmlForEditor
            this.div_formulario = false
            this.div_descripcion = true
            this.$swal({
                icon: 'success',
                title: 'Términos y Condiciones',
                text: data.data.message
            })
        }else{
            this.$swal({
                icon: 'info',
                title: 'Oops...',
                text: 'No fue posible realizar la operacion'
            })
        }
    },
    viewCondiciones(){
        if(this.htmlForEditor !== null ){
            this.$swal({
                title:'Términos y Condiciones',
                html: this.htmlForEditor,
                width: '800px',
                showCancelButton: true,
                confirmButtonColor: '#41b24c',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Rechazar',
                showCloseButton: true,
            });
        }else{
            this.$swal({
                icon: 'info',
                title: 'Términos y Condiciones',
                text: "debe ingresar datos para previsualizar el modal"
            })
        }
    },
    viewEditar(){
        this.tituloform = this.titulo
        this.htmlForEditor = this.condiciones
        this.div_descripcion = false
        this.div_formulario = true
    },
    volver(){
        this.div_descripcion = true
        this.div_formulario = false
    },
    sessionKill(){
        document.getElementById('killsession').click();
    }
  }
}
</script>
<style scoped>
.solid {
  border-top: 2px solid #babcbe;
}
.swal-wide{
    width:850px !important;
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'


// vistas del cliente
import MisDocumentos from '@/views/cliente/MisDocumentos.vue'
import Subirdocumentos from '@/views/cliente/SubirDocumentos.vue'
import LoginPasswordless from '@/views/cliente/LoginPasswordless.vue'
import IngresoCodigo from '@/views/cliente/IngresoCodigo.vue'

// vistas del ejecutivo
import EjecutivoIniciarSesion from '@/views/ejecutivo/IniciarSesion.vue'
import EjecutivoVerDocumentos from '@/views/ejecutivo/VerDocumentos.vue'
import EjecutivoRevisarDocumentos from '@/views/ejecutivo/RevisarDocumentos.vue'
import EjecutivoUsuarios from '@/views/ejecutivo/Usuarios.vue'
import CrearNuevoUsuario from '@/views/ejecutivo/CrearNuevoUsuario.vue'
import EditarUsuario from '@/views/ejecutivo/EditarUsuario.vue'
import CambiarClave from '@/views/ejecutivo/CambiarClave.vue'
import TerminoCondiciones from '@/views/ejecutivo/TerminosCondiciones.vue'
import RevisarDocumentoFinanciero from '@/views/cliente/DocumentosAxesor.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'LoginPasswordless', component: LoginPasswordless },  // cliente
  { path: '/misdocumentos', name: 'MisDocumentos', component: MisDocumentos },
  { path: '/subirdocumentos', name: 'SubirDocumentos', component: Subirdocumentos },
  { path: '/ingresocodigo', name: 'IngresoCodigo', component: IngresoCodigo },
  { path: '/ejecutivo/iniciarsesion', name: 'EjecutivoIniciarSesion', component: EjecutivoIniciarSesion }, // ejecutivo
  { path: '/ejecutivo/revisardocumentos', name: 'EjecutivoRevisarDocumentos', component: EjecutivoRevisarDocumentos },
  { path: '/documentos/:id', name: 'EjecutivoVerDocumentos', component: EjecutivoVerDocumentos },
  { path: '/ejecutivo/usuarios', name: 'EjecutivoUsuarios', component: EjecutivoUsuarios }, // admin
  { path: '/ejecutivo/crearnuevousuario', name: 'CrearNuevoUsuario', component: CrearNuevoUsuario },
  { path: '/ejecutivo/cambiarclave/:id', name: 'CambiarClave', component: CambiarClave },
  { path: '/ejecutivo/editarusuario/:id', name: 'EditarUsuario', component: EditarUsuario },
  { path: '/ejecutivo/terminocondiciones', name: 'TerminoCondiciones', component: TerminoCondiciones },
  { path: '/revisardocumentofinanciero', name: 'RevisarDocumentoFinanciero', component: RevisarDocumentoFinanciero }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router

<template>
  <div>
    <HeaderAdmin />
    <b-container>
      <b-row class="mt-3">
        <b-col>
          <h2><b-icon icon="person-plus"></b-icon> Crear Nuevo Usuario</h2>
        </b-col>
      </b-row>
      <div class="alert alert-danger" role="alert" v-if="error">
        {{ error_msg }}
      </div>
      <b-row class="mt-3">
        <b-col>
          <b-form v-on:submit.prevent="crearUsuario">
            <b-form-group id="emailForm-group" label="Email" label-for="emailForm" class="mt-3">
              <b-form-input required type="email" class="form-control-file" name="emailForm" id="emailForm" v-model="emailForm"></b-form-input>
            </b-form-group>
            <b-form-group id="perfilForm-group" label="Perfil" label-for="perfilForm" class="form-group mt-3">
              <b-form-select class="form-control" required name="perfilForm" id="perfilForm" v-model="perfilForm" :options="perfilLista"></b-form-select>
            </b-form-group>
            <b-form-group id="nombreForm-group" label="Nombre" label-for="nombreForm" class="mt-3">
              <b-form-input required class="form-control-file" name="nombreForm" id="nombreForm" v-model="nombreForm"></b-form-input>
            </b-form-group>
            <b-form-group id="apellidoForm-group" label="Apellido" label-for="apellidoForm" class="mt-3">
              <b-form-input required class="form-control-file" name="apellidoForm" id="apellidoForm" v-model="apellidoForm"></b-form-input>
            </b-form-group>
            <b-form-group id="passwordForm-group" label="Password" label-for="passwordForm" class="mt-3">
              <b-form-input required class="form-control-file" name="passwordForm" id="passwordForm" v-model="passwordForm" type="password" minlength="6"></b-form-input>
            </b-form-group>
            <b-row class="mt-3">
              <b-col>
                <b-button v-bind:to="'Usuarios'" variant="link">Cancelar</b-button>
                <b-button type="submit" variant="primary">
                  <div v-if="loading" class="spinner-border spinner-border-sm"></div> Enviar
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import HeaderAdmin from '@/components/HeaderAdmin.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';

let json = {};

export default {
  components: {
    HeaderAdmin,
    Footer,
  },
  data() {
    return {
      loading: false,
      emailForm: null,
      nombreForm: null,
      apellidoForm: null,
      passwordForm: null,
      perfilForm: null,
      perfilLista: [
        { text: '-- Seleccione Perfil --', value: null },
        { text: 'Admin', value: '1' },
        { text: 'Ejecutivo', value: '2' }
      ],
      error: false,
      erro_msg: ''
    }
  },
  mounted:function(){
    if(this.$cookies.get('token') === null){
       this.$router.push('/ejecutivo/iniciarsesion')
    }
  },
  methods: {
    // crea al usuario en la base de datos
    crearUsuario() {

      // activa el spinner del loading en el boton
      this.loading = true;

      // obtiene la observación del formulario
      json = {
        'email'     : this.emailForm,
        'perfile_id': this.perfilForm,
        'nombre'    : this.nombreForm,
        'apellido'  : this.apellidoForm,
        'password'  : this.passwordForm,
      };

      // realiza la consulta al servicio
      axios
      .post(process.env.VUE_APP_API_URL_BASE + 'api/ejecutivo/crear-usuario?token=' + this.$cookies.get('token'), json)
      .then((data) => {
        // activa el loading
        this.loading = true;
        // si llega status success, ok
        if (data.data.status === 'success') {
          // redirecciona al dashboard
          this.$router.push('Usuarios');
        }else if(data.data.status === 'info'){
          this.error = true;
          this.error_msg = data.data.message;
          // desactiva el spinner del loading en el boton
          this.loading = false;
        } else { // hay error
          this.error = true;
          this.error_msg = data.data.data;
          // desactiva el spinner del loading en el boton
          this.loading = false;
        }
      }).catch(() => {
      document.getElementById('killsession').click();
    });
    },
  },
};
</script>

<template>
  <div>
    <HeaderAdmin />
    <b-container>
      <b-row class="mt-3">
        <b-col>
          <h2><b-icon icon="pencil-square"></b-icon> Editar Usuario</h2>
        </b-col>
      </b-row>
      <div class="alert alert-danger" role="alert" v-if="error">
        {{ error_msg }}
      </div>
      <div class="alert alert-info" role="alert" v-if="info">
        {{ info_msg }}
      </div>
      <b-row class="mt-3">
        <b-col cols="6">
          <div v-if="cargando" class="spinner-border spinner-border-sm"></div>
          <div v-else>
            <b-form v-on:submit.prevent="editarUsuario">
              <b-form-group id="emailForm-group" label="Email" label-for="emailForm" class="mt-3">
                <b-form-input class="form-control-file" name="emailForm" id="emailForm" v-model="emailForm" required></b-form-input>
              </b-form-group>
              <b-form-group id="perfilForm-group" label="Perfil" label-for="perfilForm" class="mt-3">
                <b-form-select name="perfilForm" id="perfilForm" v-model="perfilForm" :options="perfilLista" required></b-form-select>
              </b-form-group>
              <b-form-group id="nombreForm-group" label="Nombre" label-for="nombreForm" class="mt-3">
                <b-form-input class="form-control-file" name="nombreForm" id="nombreForm" v-model="nombreForm" required></b-form-input>
              </b-form-group>
              <b-form-group id="apellidoForm-group" label="Apellido" label-for="apellidoForm" class="mt-3">
                <b-form-input class="form-control-file" name="apellidoForm" id="apellidoForm" v-model="apellidoForm" required></b-form-input>
              </b-form-group>
              <b-form-group id="estadoForm-group" label="Estado" label-for="estadoForm" class="mt-3">
                <b-form-select name="estadoForm" id="estadoForm" v-model="estadoForm" :options="estadoLista" required></b-form-select>
              </b-form-group>
              <b-row class="mt-3">
                <b-col>
                  <b-button v-bind:to="'/ejecutivo/Usuarios'" variant="link">Cancelar</b-button>
                  <b-button type="submit" variant="primary">
                    <div v-if="loading" class="spinner-border spinner-border-sm"></div> Guardar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import HeaderAdmin from '@/components/HeaderAdmin.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';

let json = {};

export default {
  components: {
    HeaderAdmin,
    Footer,
  },
  mounted:function() {
    if(this.$cookies.get('token') === null){
       this.$router.push('/ejecutivo/iniciarsesion')
    }
    // llama al servicio que obtiene los usuarios
    axios
    .post(process.env.VUE_APP_API_URL_BASE + 'api/ejecutivo/obtener-usuario?id=' + this.$route.params.id + '&token=' + this.$cookies.get('token'),{data:{}},{
        headers:{
          'Content-Type': 'application/json'
        }
      })
    .then((data) => {
      // desactiva el loading
      this.cargando = false;
      // si llega success
      if (data.data.status === 'success') {
        // guarda en los usuarios
        this.usuario = data.data.data;
        this.emailForm = this.usuario.email;
        this.nombreForm = this.usuario.nombre;
        this.apellidoForm = this.usuario.apellido;
        this.perfilForm = this.usuario.perfile_id;
        this.estadoForm = this.usuario.estado;
      } else { // llega un error, marca error
        this.error     = true;
        this.error_msg = data.data.data;
      }
    }).catch((error) => {
        this.sessionKill(error)
    });
  },
  data() {
    return {
      loading: false,
      emailForm: null,
      nombreForm: null,
      apellidoForm: null,
      perfilForm: null,
      cargando: true,
      perfilLista: [
        { text: '-- Seleccione Perfil --', value: null },
        { text: 'Admin', value: '1' },
        { text: 'Ejecutivo', value: '2' }
      ],
     estadoLista: [
        { text: '-- Seleccione Estado --', value: null },
        { text: 'Activo', value: '1' },
        { text: 'Inactivo', value: '0' }
      ],
      error: false,
      erro_msg: '',
      info: false,
      info_msg: '',
      usuario        : {
        email: '',
        nombre: '',
        apellido: '',
        perfil_nombre: '',
        estado: '',
      }
    }
  },
  methods: {
    // crea al usuario en la base de datos
    editarUsuario() {
      // activa el spinner del loading en el boton
      this.loading = true;
      // obtiene la observación del formulario
      json = {
        'id'        : this.$route.params.id,
        'email'     : this.emailForm,
        'perfile_id': this.perfilForm,
        'nombre'    : this.nombreForm,
        'apellido'  : this.apellidoForm,
        'estado'    : this.estadoForm,
      };
      // realiza la consulta al servicio
      axios
      .post(process.env.VUE_APP_API_URL_BASE + 'api/ejecutivo/editar-usuario?token=' + this.$cookies.get('token'), json)
      .then((data) => {
        // activa el loading
        this.loading = true;
        // si llega status success, ok
        if (data.data.status === 'success') {
          // redirecciona al dashboard
          this.$router.push('/ejecutivo/Usuarios');
         }else if(data.data.status === 'info'){
          this.info = true;
          this.info_msg = data.data.message;
          // desactiva el spinner del loading en el boton
          this.loading = false;
        } else { // hay error
          this.error = true;
          this.error_msg = data.data.data;
          // desactiva el spinner del loading en el boton
          this.loading = false;
        }
      }).catch((error) => {
        this.sessionKill(error)
    });
    },
    sessionKill(){
      document.getElementById('killsession').click();
    }
  },
};
</script>

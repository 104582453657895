<template>
  <div class="home">
    <div class="wrapper fadeInDown">
      <div id="formContent">
        <div class="fadeIn first">
          <img src="@/assets/logo_copec.svg" id="logo" alt="Logo Copec" />
        </div>
        <div class="p-2">
          <p>
            Ingrese el RUT de su Empresa para solicitar un código de
            acceso al Sistema
          </p>
        </div>
        <form v-on:submit.prevent="login">
          <input type="text" required id="login" class="fadeIn second" name="login" placeholder="Ejemplo: 78854772-K" v-model="rut" />
          <b-button v-if="!loading" type="submit" size="lg" class="btn-copec  m-1 mt-2 mb-5">
            Solicitar Código
          </b-button>
          <div v-if="loading" class="mt-2 mb-5 text-primary">
           <button class="btn btn-primary btn-lg" disabled><div class="spinner-border spinner-border-sm"></div> Solicitando Código</button>
          </div>
        </form>
        <div class="alert alert-danger mx-3" role="alert" v-if="error">
          {{ error_msg }}
        </div>
      </div>
      <b-link class="mt-5" v-bind:to="'/ejecutivo/iniciarsesion'" variant="link">Login Ejecutivo</b-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Home',
  components: {},
  data: function () {
    return {
      rut     : '',
      password: '',
      error   : false,
      erro_msg: '',
      loading : false
    };
  },

  methods: {
    login() {

      // activa el loading
      this.loading = true;

      let json = {
        rut: this.rut,
      };

      // llamada al servicio
      axios
      .post(process.env.VUE_APP_API_URL_BASE + 'api/cliente/solicitar-codigo', json)
      .then((data) => {

        // si llega success
        if (data.data.status === 'success') {

          // guarda en las cookies el rut
          this.$cookies.set('rut', data.data.data.rut);

          // redirecciona al dashboard
          this.$router.push('IngresoCodigo');

        } else { // llega un error, marca error
          this.error     = true;
          this.error_msg = data.data.data;

          // desactiva el spinner de loading
          this.loading = false;
        }
      }).catch((error) => {
        if(error.response.status === 400){
            this.$swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Ha ocurrido un error por favor reintente nuevamente'
            })
        }
    });
    },

    // test
    testApi() {
      axios
      .get(process.env.VUE_APP_API_URL_BASE + 'api/test')
      .then((data) => {
        alert(data.data.response);
      }).catch((error) => {
          if(error.response.status === 400){
              this.$swal({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Ha ocurrido un error por favor reintente nuevamente'
              })
          }
      });
    }
  },
};
</script>

<style scoped>
html {
  background-color: #56baed;
}

body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

a {
  color: #92badd;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  margin: 40px 8px 10px 8px;
  color: #cccccc;
}

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 200px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/

input[type="text"] {
  background-color: #f6f6f6;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type="text"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type="text"]:placeholder {
  color: #cccccc;
}

/* OTHERS */

*:focus {
  outline: none;
}

#logo {
  width: 40%;
  margin: 50px;
}
</style>

<template>
  <div>
    <HeaderCliente />
    <b-container class="bg-white">
      <b-row class="mt-3">
        <b-col>
          <h4 class="text-copec"> Mis Documentos</h4>
        </b-col>
        <b-col class="text-right">
          <b-button class="btn-copec mt-1 btn-sm" v-bind:to="'Subirdocumentos'"><b-icon icon="cloud-upload"></b-icon> Subir Documentos</b-button>
        </b-col>
        <b-col class="text-right">
          <b-button class="btn-copec mt-1 btn-sm" v-bind:to="'revisardocumentofinanciero'"><b-icon icon="cloud-upload"></b-icon> Subir Documentos con Floid</b-button>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <table class="table bg-white">
            <caption>Mis Documentos</caption>
            <thead>
              <tr>
                <th id="h-fecha" class="text-center">Fecha</th>
                <th id="h-estado" class="text-center">Estado</th>
                <th id="h-documentos" class="text-center">Documentos</th>
                <th id="h-comentario" class="comentario">Notas</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="4" style="vertical-align: middle">
                  <date-picker v-model="filtroFecha" range :lang="lang"></date-picker>
                  &nbsp;
                  <b-button v-on:click.prevent="filtrarPorFecha()"   variant="m-1" class="btn btn-copec btn-sm"><b-icon icon="search"></b-icon> Buscar</b-button>
                </td>

              </tr>
              <tr v-for="(documento, index) in documentos" v-bind:key="index">
                <td class="text-nowrap text-center">
                  {{ documento.creacion | luxon('dd-MM-yyyy') }} <br />
                  <small>{{ documento.creacion | luxon('HH:mm') }}</small>
                </td>
                <td class="text-nowrap text-center">
                  <span v-if="documento.estado_id == 1"><b-icon icon="hourglass" scale="1.5"></b-icon><br>{{ documento.estado }}</span>
                  <span v-if="documento.estado_id == 2" class="text-danger"><b-icon icon="x-circle-fill" scale="1.5"></b-icon><br>{{ documento.estado }}</span>
                  <span v-if="documento.estado_id == 3" class="text-success"><b-icon icon="check-circle-fill" scale="1.5"></b-icon><br>{{ documento.estado }}</span>
                </td>
                <td class="text-nowrap text-center celda-documentos">
                  <b-row>
                    <b-col
                      v-bind:title="documento.documento_floid"
                      v-bind:class="[documento.documento_floid ? 'manito' : 'text-deshabilitado']"
                      v-on:click.prevent="[documento.documento_floid ? descargar(documento.id, 'documento_floid') : '']"
                      class="text-center"
                    >
                      <b-row><b-col><b-icon icon="file-earmark-text" scale="1.5"></b-icon></b-col></b-row>
                      <b-row><b-col class="texto-doc">Documento floid</b-col></b-row>
                    </b-col>
                    <b-col
                      v-bind:title="documento.balance"
                      v-bind:class="[documento.balance ? 'manito' : 'text-deshabilitado']"
                      v-on:click.prevent="[documento.balance ? descargar(documento.id, 'balance') : '']"
                      class="text-center"
                    >
                      <b-row><b-col><b-icon icon="file-earmark-text" scale="1.5"></b-icon></b-col></b-row>
                      <b-row><b-col class="texto-doc">Balance</b-col></b-row>
                    </b-col>
                    <b-col
                      v-bind:title="documento.estado_de_resultados"
                      v-bind:class="[documento.estado_de_resultados ? 'manito' : 'text-deshabilitado']"
                      v-on:click.prevent="[documento.estado_de_resultados ? descargar(documento.id, 'estado_de_resultados') : '']"
                      class="text-center"
                    >
                      <b-row><b-col><b-icon icon="file-earmark-text" scale="1.5"></b-icon></b-col></b-row>
                      <b-row><b-col class="texto-doc">Estado de<br>Resultados</b-col></b-row>
                    </b-col>
                    <b-col
                      v-bind:title="documento.carpeta_tributaria"
                      v-bind:class="[documento.carpeta_tributaria ? 'manito' : 'text-deshabilitado']"
                      v-on:click.prevent="[documento.carpeta_tributaria ? descargar(documento.id, 'carpeta_tributaria') : '']"
                      class="text-center"
                    >
                      <b-row><b-col><b-icon icon="file-earmark-text" scale="1.5"></b-icon></b-col></b-row>
                      <b-row><b-col class="texto-doc">Carpeta<br>Tributaria</b-col></b-row>
                    </b-col>
                    <b-col
                      v-bind:title="documento.otro_documento"
                      v-bind:class="[documento.otro_documento ? 'manito' : 'text-deshabilitado']"
                      v-on:click.prevent="[documento.otro_documento ? descargar(documento.id, 'otro_documento') : '']"
                      class="text-center"
                    >
                      <b-row><b-col><b-icon icon="file-earmark-text" scale="1.5"></b-icon></b-col></b-row>
                      <b-row><b-col class="texto-doc">Otro<br>Documento</b-col></b-row>
                    </b-col>
                  </b-row>
    
                </td>
                <td class="comentario">
                  <b-row v-if="documento.observacion">
                    <b-col><strong>Observación:</strong> {{ documento.observacion }}</b-col>
                  </b-row>
                  <b-row v-if="documento.fecha_revision">
                    <b-col><strong>Revisión {{ documento.fecha_revision | luxon('dd-MM-yyyy HH:mm') }}<span v-if="documento.mensaje_revision">:</span></strong> {{ documento.mensaje_revision }}</b-col>
                  </b-row>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="loading" class="text-center">
            <div class="spinner-border"></div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import HeaderCliente from '@/components/HeaderCliente.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {

  components: {
    HeaderCliente,
    Footer,
    DatePicker
  },

  mounted:function(){
    if(this.$cookies.get('token') === null){
       this.$router.push('/')
    }
    document.getElementById('text_up_doc').classList.remove('text-copec')
    document.getElementById('text_doc').classList.add('text-copec')
    // llamada al servicio
    this.obtener_documentos()
  },

  data: function ()
  {
    return {
      documentos: null,
      loading   : true,
      error     : false,
      erro_msg  : '',
      filtroFecha: null,
      lang: this.paramsLangDatepicker(),
    };
  },

  methods: {
    descargar(documento_id, tipo) {
      this.$swal({
          title: 'Obteniendo documento ',
          allowOutsideClick: false,
          didOpen: () => {
              this.$swal.showLoading()
          },
      })
      let url = process.env.VUE_APP_API_URL_BASE + 'api/ejecutivo/descargar-documento/' + documento_id + '?tipo=' + tipo + '&token=' + this.$cookies.get('token');
      axios.post(url,{data:{}},{
        headers:{
          'Content-Type': 'application/json'
        }
      }).then((data) => {
        // desactiva loading del sitio
        if(data.data.status === 'success'){
          this.$swal.close()
          window.open(data.data.url);
        }else{
          this.$swal({
              icon: 'info',
              title: 'No fue posible obtener el documento'
            })
        }
      }).catch((error) => {
        this.sessionKill(error)
      });
      },

    async obtener_documentos(){
      await axios
      .post(process.env.VUE_APP_API_URL_BASE + 'api/cliente/mis-documentos?token=' + this.$cookies.get('token'),{data:{}},{
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((data) => {
        // desactiva el spinner de loading
        this.loading = false;
        // si llega success
        if (data.data.status === 'success') {

          // asigna los datos de los documentos a la variable
          this.documentos = data.data.data;

        } else { // llega un error, marca error
          this.error     = true;
          this.error_msg = data.data.data;
        }
      }).catch((error) => {
        this.sessionKill(error)
      });
    },

    filtrarPorFecha() {
      this.documentos = null;
      this.cargando = true;
      let querystring = '';

      // si coloca estado, agrega el querystring el estado
      if (this.filtroFecha && this.filtroFecha[0]) {
        querystring += '&desde=' + this.$luxon(this.filtroFecha[0].toISOString(), 'yyyy-MM-dd')
        + '&hasta=' + this.$luxon(this.filtroFecha[1].toISOString(), 'yyyy-MM-dd');
      }

      // llama al servicio que obtiene los documentos
      axios.get(process.env.VUE_APP_API_URL_BASE + 'api/cliente/mis-documentos?token=' + this.$cookies.get('token') + querystring).then((data) => {
        // desactiva loading del sitio
        this.cargando = false;
        // si llega success
        if (data.data.status === 'success') {
          // guarda los documentos
          this.documentos = data.data.data;
        } else { // llega un error, marca error
          this.error     = true;
          this.error_msg = data.data.data;
        }
      }).catch(() => {
        this.sessionKill()
      });
    },
     sessionKill(){
        document.getElementById('killsession').click();
    }
  }
};
</script>

<style scoped>
.comentario{
  max-width: 300px;
}

.text-deshabilitado {
  color: #BBBBBB;
}

table {
  font-size: small;
}

.manito {
  cursor: pointer;
}

.celda-documentos {
  padding-left: 40px;
  padding-right: 40px;
}

#h-documentos {
 min-width: 400px
}

.texto-doc {
    line-height: 1rem;
    margin-top: 0.3rem;
}

.mx-datepicker-range  {
  width: 220px;
}
</style>

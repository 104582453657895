<template>
  <div class="mt-3 sombra">
    <b-navbar toggleable="md" type="light" variant="white">
      <b-container>
        <b-navbar-brand>
          <img src="@/assets/logo_copec.svg" id="logo" alt="Logo Copec" />
        </b-navbar-brand>
        <b-navbar-brand>
          <div class="alert alert-info" role="alert" v-if="enviroment == 'TESTING'">Se encuentra en el entorno de TESTING </div>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item v-bind:to="'/ejecutivo/RevisarDocumentos'"><span class="text-copec" >Documentos</span></b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown right>
              <!-- Using 'button-content' slot -->
              <template #button-content>
                <em><b-icon icon="person-circle"></b-icon>{{ email }}</em>
              </template>
              <b-dropdown-item v-on:click.prevent="cambiarClave(id)"><b-icon icon="lock"></b-icon> Cambiar Contraseña</b-dropdown-item>
              <b-dropdown-item v-on:click.prevent="logout()"><b-icon icon="box-arrow-right"></b-icon> Salir</b-dropdown-item>
              <b-dropdown-item style="display:none" id="killsession" v-on:click.prevent="killSessionEjecutivo()"><b-icon icon="box-arrow-right"></b-icon> killSession</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>

export default ({
  data: function() {
    return {
      email: this.$cookies.get('email'),
      id: this.$cookies.get('id'),
      enviroment: process.env.ENVIROMENT,
    };
  },

  methods: {
  }
});
</script>

<style scoped>
* {
  font-size: 1rem;
  color: #7C97AB;
}
.ml-auto {
  margin-left: auto;
}
.navbar.navbar-dark.bg-dark {
  background-color: #aabb55 !important;
}
#logo {
  width: 6rem;
}
</style>

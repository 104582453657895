<template>
  <div>
    <HeaderEjecutivo />
    <b-container>
      <b-row class="mt-3">
        <b-col>
          <h4 class="text-copec">Documentos</h4>
        </b-col>
      </b-row>
      <div class="alert alert-danger" role="alert" v-if="error">
        {{ error_msg }}
      </div>
      <b-row class="mt-3">
        <b-col>
          <table class="table bg-white">
            <caption>Documentos</caption>
            <thead>
              <tr>
                <th id="h-empresa">Empresa</th>
                <th id="h-fecha" class="text-center">Fecha</th>
                <th id="h-documentos" class="text-center">Documentos</th>
                <th id="h-oficinas" class="text-center">Oficina</th>
                <th id="h-estado" class="text-center">Estado</th>
                <th id="h-notas" class="comentario">Notas</th>
                <th id="h-acciones" class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <b-form-input v-model="filtroEmpresa" size="sm" placeholder="-- Todas las Empresas --"></b-form-input>
                </td>
                <td colspan="2">
                  <date-picker v-model="filtroFecha" range :lang="lang"></date-picker>
                </td>
                <td >
                  <!--<b-form-input v-model="filtroOficina" size="sm" placeholder="-- Todas las Oficinas --" :options="filtroEstadoOpciones"></b-form-input>-->
                  <b-form-select class="form-control form-control-sm" v-model="filtroOficina" :options="filtroOficinaOpciones"></b-form-select>
                </td>
                <td colspan="2">
                    <b-form-select class="form-control form-control-sm" v-model="filtroEstado" :options="filtroEstadoOpciones"></b-form-select>
                </td>

                <td class="text-center"><b-button v-on:click.prevent="filtrar()" size="sm" class="btn-copec"><b-icon icon="search"></b-icon> Buscar</b-button></td>
              </tr>
              <tr v-for="(documento, index) in documentos" v-bind:key="index">
                <td>
                  {{ documento.cliente_nombre }}<br>
                  <small>{{ documento.cliente_rut }}</small>
                </td>
                <td class="text-nowrap text-center">
                  {{ documento.creacion | luxon('dd-MM-yyyy') }} <br />
                  <small>{{ documento.creacion | luxon('HH:mm') }}</small>
                </td>
                <td class="text-nowrap text-center celda-documentos">
                  <b-row>
                    <b-col
                      v-bind:title="documento.documento_floid"
                      v-bind:class="[documento.documento_floid ? 'manito' : 'text-deshabilitado']"
                      v-on:click.prevent="[documento.documento_floid ? descargar(documento.id, 'documento_floid') : '']"
                      class="text-center"
                    >
                      <b-row><b-col><b-icon icon="file-earmark-text" scale="1.5"></b-icon></b-col></b-row>
                      <b-row><b-col class="texto-doc">Documento floid</b-col></b-row>
                    </b-col>
                    <b-col
                      v-bind:title="documento.balance"
                      v-bind:class="[documento.balance ? 'manito' : 'text-deshabilitado']"
                      v-on:click.prevent="[documento.balance ? descargar(documento.id, 'balance') : '']"
                      class="text-center"
                    >
                      <b-row><b-col><b-icon icon="file-earmark-text" scale="1.5"></b-icon></b-col></b-row>
                      <b-row><b-col class="texto-doc">Balance</b-col></b-row>
                    </b-col>
                    <b-col
                      v-bind:title="documento.estado_de_resultados"
                      v-bind:class="[documento.estado_de_resultados ? 'manito' : 'text-deshabilitado']"
                      v-on:click.prevent="[documento.estado_de_resultados ? descargar(documento.id, 'estado_de_resultados') : '']"
                      class="text-center"
                    >
                      <b-row><b-col><b-icon icon="file-earmark-text" scale="1.5"></b-icon></b-col></b-row>
                      <b-row><b-col class="texto-doc">Estado de<br>Resultados</b-col></b-row>
                    </b-col>
                    <b-col
                      v-bind:title="documento.carpeta_tributaria"
                      v-bind:class="[documento.carpeta_tributaria ? 'manito' : 'text-deshabilitado']"
                      v-on:click.prevent="[documento.carpeta_tributaria ? descargar(documento.id, 'carpeta_tributaria') : '']"
                      class="text-center"
                    >
                      <b-row><b-col><b-icon icon="file-earmark-text" scale="1.5"></b-icon></b-col></b-row>
                      <b-row><b-col class="texto-doc">Carpeta<br>Tributaria</b-col></b-row>
                    </b-col>
                    <b-col
                      v-bind:title="documento.otro_documento"
                      v-bind:class="[documento.otro_documento ? 'manito' : 'text-deshabilitado']"
                      v-on:click.prevent="[documento.otro_documento ? descargar(documento.id, 'otro_documento') : '']"
                      class="text-center"
                    >
                      <b-row><b-col><b-icon icon="file-earmark-text" scale="1.5"></b-icon></b-col></b-row>
                      <b-row><b-col class="texto-doc">Otro<br>Documento</b-col></b-row>
                    </b-col>
                  </b-row>
                </td>
                <td>
                  {{ documento.cliente_oficina == null ? '--' : documento.cliente_oficina }}
                </td>
                <td class="text-nowrap text-center">
                  <span v-if="documento.estado_id == 1"><b-icon icon="hourglass" scale="1.5"></b-icon><br>{{ documento.estado }}</span>
                  <span v-if="documento.estado_id == 2" class="text-danger"><b-icon icon="x-circle-fill" scale="1.5"></b-icon><br>{{ documento.estado }}</span>
                  <span v-if="documento.estado_id == 3" class="text-success"><b-icon icon="check-circle-fill" scale="1.5"></b-icon><br>{{ documento.estado }}</span>
                </td>
                <td class="comentario">
                  <b-row v-if="documento.observacion">
                    <b-col><strong>Observación:</strong> {{ documento.observacion }}</b-col>
                  </b-row>
                  <b-row v-if="documento.fecha_revision">
                    <b-col>
                      <strong>Revisión {{ documento.fecha_revision | luxon('dd-MM-yyyy HH:mm') }}<span v-if="documento.mensaje_revision">:</span></strong> {{ documento.mensaje_revision }}
                    </b-col>
                  </b-row>
                </td>
                <td class="text-nowrap">
                  <div v-if="documento.estado_id == 1 && ! loading">
                    <b-button v-on:click.prevent="aprobarDocumento(documento.id)" size="sm" variant="outline-success"><b-icon icon="check-circle-fill"></b-icon> Aprobar</b-button>&nbsp;
                    <b-button v-on:click.prevent="rechazarDocumento(documento.id)" size="sm" variant="outline-danger" class="pl-1"><b-icon icon="x-circle-fill"></b-icon> Rechazar</b-button><br>
                  </div>
                  <div v-if="loading">
                    <div class="spinner-border spinner-border-sm"></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="cargando" class="text-center">
            <div class="spinner-border"></div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import HeaderEjecutivo from '@/components/HeaderEjecutivo.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';


export default {

  components: {
    HeaderEjecutivo,
    Footer,
    DatePicker
  },

  mounted:function() {
    if(this.$cookies.get('token') === null){
       this.$router.push('/ejecutivo/iniciarsesion')
    }
    // llama al servicio que obtiene los documentos
    this.filtrar()
    this.getoficinas()
  },
  data: function ()
  {
    return {
      documentos: null,
      error     : false,
      erro_msg  : '',
      loading   : false,
      cargando  : true,
      filtroEmpresa: null,
      filtroFecha: null,
      filtroEstado: null,
      filtroOficina: "",
      filtroOficinaOpciones : [],
      filtroEstadoOpciones: [
        { value: null, text: '-- Todos los Estados --' },
        { value: '1', text: 'Pendiente' },
        { value: '2', text: 'Rechazado' },
        { value: '3', text: 'Aprobado' }
      ],
      lang: this.paramsLangDatepicker(),
    };
  },
  methods: {
    filtrar() {
      this.documentos = null;
      this.cargando = true;
      let querystring = "";
      // si coloca empresa, agrega el querystring la empresa
      if (this.filtroEmpresa) {
        querystring += "&empresa=" + this.filtroEmpresa;
      }

      //si coloca oficina
      if (this.filtroOficina){
        querystring += "&oficina=" + this.filtroOficina
      }
      // si coloca estado, agrega el querystring el estado
      if (this.filtroFecha && this.filtroFecha[0]) {
        querystring += "&desde=" + this.$luxon(this.filtroFecha[0].toISOString(), 'yyyy-MM-dd')
        + "&hasta=" + this.$luxon(this.filtroFecha[1].toISOString(), 'yyyy-MM-dd');
      }
      // si coloca estado, agrega el querystring el estado
      if (this.filtroEstado) {
        querystring += "&estado=" + this.filtroEstado;
      }
      // llama al servicio que obtiene los documentos      
      axios
      .post(process.env.VUE_APP_API_URL_BASE + 'api/ejecutivo/listado-documentos?token=' + this.$cookies.get('token') + querystring,{data:{}},{
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((data) => {
        // desactiva loading del sitio
        this.cargando = false;
        // si llega success
        if (data.data.status === 'success') {
          // guarda los documentos
          this.documentos = data.data.data;
        } else { // llega un error, marca error
          this.error     = true;
          this.error_msg = data.data.data;
        }
      }).catch((error) => {
          this.sessionKill(error)
    });
    },getoficinas : async function (){
    const rest = await axios
      .post(process.env.VUE_APP_API_URL_BASE + 'api/ejecutivo/obtener-oficina',{data:{}},{
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((data) => {
        return data.data.data
      })

      this.filtroOficinaOpciones = rest
  },
    descargar(documento_id, tipo) {
      let url = process.env.VUE_APP_API_URL_BASE + 'api/ejecutivo/descargar-documento/' + documento_id + '?tipo=' + tipo + '&token=' + this.$cookies.get('token');
      this.$swal({
          title: 'Obteniendo documento ',
          allowOutsideClick: false,
          didOpen: () => {
              this.$swal.showLoading()
          },
      })
      axios
      .post(url,{data:{}},{
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then((data) => {
        // desactiva loading del sitio
        if(data.data.status === 'success'){
          this.$swal.close()
          window.open(data.data.url);
        }else{
          this.$swal({
              icon: 'info',
              title: 'No fue posible obtener el documento'
            })
        }
      }).catch((error) => {
          this.sessionKill(error)
    });
    },
    // aprueba el documento
    aprobarDocumento(id) {
      // activa el loading
      this.loading = true;
      // alerta
      this.$swal({
        title             : 'Aprobar Documentos',
        text              : 'Se enviará un correo al proveedor y se enviará registro a SAP. ¿Está seguro que desea aprobar estos documentos?',
        showCancelButton  : true,
        allowOutsideClick: false,
        cancelButtonText  : 'Cancelar',
        confirmButtonColor: '#198754',
        confirmButtonText : '¡Sí, Aprobar!'
      }).then((result) => {
        // si confirma
        if (result.value) {
          // obtiene el id del documento para el json del post
          const json = { documentoId: id };
          // llama al servicio
          axios
          .post(process.env.VUE_APP_API_URL_BASE + 'api/ejecutivo/aprobar-documento?token=' + this.$cookies.get('token'), json)
          .then((data) => {

            // si llega success
            if (data.data.status === 'success') {
              // redirecciona al mismo sitio
              this.$router.go(this.$router.currentRoute);
            }else if(data.data.status === 'info'){
              this.loading = false;
              this.$swal.fire({
                icon: 'info',
                text: 'El documento ya ha sido aprobado'
              })
            } else { // llega un error, marca error
             this.error = true;
              this.error_msg = data.data.data;
              // desactiva el loading
              this.loading = false;
            }
          }).catch((error) => {
              this.sessionKill(error)
          });
        } else {
          this.loading = false;
        }
      });
    },
    // rechaza el documento
    rechazarDocumento(id) {
      // activa el loading
      this.loading = true;
      // alerta
      this.$swal({
        title             : 'Rechazar Documentos',
        text              : 'Se enviará un email al proveedor con el motivo del rechazo. ¿Está seguro que desea rechazar estos documentos?',
        input             : 'textarea',
        inputPlaceholder  : 'Ingrese el motivo del rechazo',
        showCancelButton  : true,
        cancelButtonText  : 'Cancelar',
        confirmButtonColor: '#dc3545',
        confirmButtonText : '¡Sí, Rechazar!',
        preConfirm: (texto) => {
          if (! texto) {
            this.$swal.showValidationMessage('Debe ingresar motivo el motivo del rechazo');
          }
          // retorna el texto si es que venía algo
          return texto;
        },
      }).then((result) => {
        // si confirma
        if (result.value) {
          // obtiene el id del documento y el mensaje de rechazo para el json del post
          const json = {
            documentoId    : id,
            mensajeRevision: result.value
          };
          // llama al servicio
          axios
          .post(process.env.VUE_APP_API_URL_BASE + 'api/ejecutivo/rechazar-documento?token=' + this.$cookies.get('token'), json)
          .then((data) => {
            // si llega success
            if (data.data.status === 'success') {
              // redirecciona al mismo sitio
              this.$router.go(this.$router.currentRoute);
            } else { // llega un error, marca error
              this.error = true;
              this.error_msg = data.data.data;
              // desactiva el loading
              this.loading = false;
            }
          }).catch((error) => {
              this.sessionKill(error)
        });
        } else {
          this.loading = false;
        }
      });
    },
    sessionKill(){
      document.getElementById('killsession').click();
    }
  }
};
</script>

<style scoped>
.comentario{
  max-width: 300px;
}

.text-deshabilitado {
  color: #BBBBBB;
}

table {
  font-size: small;
}

td {
  vertical-align: middle;
}

.manito {
  cursor: pointer;
}

.texto-doc {
    line-height: 1rem;
    margin-top: 0.3rem;
}

.mx-datepicker-range  {
  width: 220px;
}

.custom-select {
  width: 165px;
}

.celda-documentos {
  padding-left: 40px;
  padding-right: 40px;
}

#h-documentos {
 min-width: 400px
}


.row > *{
  font-size: 11px ;
  padding-right: 1.5px;
}

</style>

<template>
  <div>
    <HeaderCliente />
    <b-container class="bg-white">
      <b-row class="mt-3">
        <b-col>
          <h2><b-icon icon="cloud-upload"></b-icon> Subir Documentos</h2>
        </b-col>
      </b-row>
      <div class="alert alert-danger" role="alert" v-if="error">
        {{ error_msg }}
      </div>
      <b-row class="mt-3">
        <b-col>
          <form v-on:submit.prevent="terminar" enctype="multipart/form-data">
            <div class="form-group mt-3">
              <strong><div  v-if="isUploading" class="spinner-border spinner-border-sm"></div> Balance *</strong><br>
              <input type="file" required class="form-control-file input-archivo" name="balance" data-name="Balance"  id="balance" v-on:change="onFileChange" ref="balance" />
            </div>
            <div class="form-group mt-3">
              <strong><div  v-if="isUploading" class="spinner-border spinner-border-sm"></div> Estado de Resultados *</strong><br>
              <input type="file" required class="form-control-file input-archivo" name="estado_de_resultados" data-name="Estado de Resultados" id="estado_de_resultados" v-on:change="onFileChange" ref="estado_de_resultados"/>
            </div>
            <div class="form-group mt-3">
              <strong><div  v-if="isUploading" class="spinner-border spinner-border-sm"></div> Carpeta Tibutaria</strong><br>
              <input type="file" class="form-control-file input-archivo" name="carpeta_tributaria" id="carpeta_tributaria"  data-name="Carpeta Tibutaria" v-on:change="onFileChange" ref="carpeta_tributaria"/>
            </div>
            <div class="form-group mt-3">
              <strong><div  v-if="isUploading" class="spinner-border spinner-border-sm"></div> Otro Documento</strong><br>
              <input type="file" class="form-control-file input-archivo" name="otro_documento" id="otro_documento"  data-name="Otro Documento" v-on:change="onFileChange" ref="otro_documento"/>
            </div>
            <div class="form-group mt-3">
              <label for="observacionForm"><strong>Observación</strong></label>
              <textarea class="form-control" id="observacionForm" rows="5" v-model="observacionForm"></textarea>
            </div>
            <b-row class="mt-3">
              <b-col>
                <b-button v-bind:to="'MisDocumentos'" variant=" m-1" class="btn btn-outline-copec ">Cancelar</b-button>
                <b-button v-if="!loading" type="submit" class="btn-copec" v-on:click="terminar()" >
                  Subir Documentos
                </b-button>
                <span v-if="loading">
                  <button class="btn btn-primary" disabled><div class="spinner-border spinner-border-sm"></div> Subiendo Documentos</button>
                </span>
              </b-col>
            </b-row>
          </form>
        </b-col>
        <b-col>
          <div>
            <b-card title="Restricciones" sub-title="El formulario debe cumplir con lo siguiente:" class="mx-5">
              <ul>
                <li>El tamaño de cada archivo no puede ser superio a {{ tamagnoMaximoMb }} MB.</li>
                <li>Los formatos permitidos son {{ archivosPermitidosHumano.join(', ') }}.</li>
                <li>Los archivos con * son obligatorios</li>
              </ul>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import HeaderCliente from '@/components/HeaderCliente.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';

// datos del formulario
let fd = new FormData();

export default {
  components: {
    HeaderCliente,
    Footer,
  },

  data() {
    return {
      isUploading: false,
      idDocumento: null,
      loading: false,
      observacionForm: '',
      error: false,
      error_msg: false,
      tamagnoMaximoMb: 3,
      archivosPermitidos: [
        'application/pdf',
        'image/png',
        'image/jpeg',
        'image/gif'
        ],
      archivosPermitidosHumano: ['pdf', 'png', 'jpeg', 'jpg', 'gif']
    }
  },

  mounted:function(){

    if(this.$cookies.get('token') === null){
       this.$router.push('/')
    }
    document.getElementById('text_doc').classList.remove('text-copec')
    document.getElementById('text_up_doc').classList.add('text-copec')
  },

  methods: {
    // se coloca un documento en el formulario
    onFileChange(event) {
      // deshabilitar los campos
      if (event.target.value !== ''){
          this.deshabilitarInputs();
                // se desactivan los errores y comienza loading
                this.error = false;
                this.isUploading = true;
                // tamaño, nombre y tipo de archivo
                let tamagnoEnMb = event.target.files[0].size / 1024 / 1024;
                let nombreArchivo = event.target.files[0].name;
                let tipoArchivo = event.target.files[0].type;
                // si viene un archivo
                if (event.target.files.length) {
                  // si no corresponde al tipo de archivo
                  if (! this.archivosPermitidos.includes(tipoArchivo)) {
                    // resetea el input
                    this.$refs[event.target.name].value = null;
                    // arroja un error
                    this.error = true;
                    this.error_msg = "El archivo ingresado con nombre " + nombreArchivo + " no cumple con el formato. Los formatos permitidos son: " + this.archivosPermitidosHumano.join(', ') + ".";
                    this.isUploading = false;
                    this.habilitarInputs();
                  } else if (tamagnoEnMb > this.tamagnoMaximoMb) { // si sobrepasa el tamaño maximo del archivo
                    // resetea el input
                    this.$refs[event.target.name].value = null;
                    // arroja error
                    this.error = true;
                    this.error_msg = "El archivo ingresado con nombre " + nombreArchivo + " es demasiado grande. El tamaño máximo permitido es de " + this.tamagnoMaximoMb + " MB."
                    this.isUploading = false;
                    this.habilitarInputs();
                  } else {
                  this.isUploading = false;
                  this.habilitarInputs();
                  }
                } else { // no viene el archivo, lo elimina si es que hay
                  this.isUploading = false;
                  this.habilitarInputs();
                }
      }else{
          this.isUploading = false;
          this.habilitarInputs();
      }
    },
    // guarda el documento
    terminar() {
      // alerta
      this.$swal({
        title             : 'Subir Documentos',
        text              : 'Con esta acción usted declara que ha finalizado. ¿Está seguro que desea enviar estos documentos?',
        showCancelButton  : true,
        cancelButtonText  : 'Cancelar',
        cancelButtonColor :  "#d33",
        confirmButtonColor: '#0d6efd',
        confirmButtonText : '¡Sí, Enviar!'
      }).then((result) => {
        // si confirma
        if (result.value) {
          let documentos = document.getElementsByClassName('input-archivo');
          if(documentos[0].value !== '' && documentos[1].value !== ''){
            this.loading = true;
            fd.delete("comentario")
            if(this.observacionForm !== ''){
              fd.append("comentario", this.observacionForm)
            }
            // si viene un documento id (no es el primer documento), lo añade al query string
            // elimina lo que hay en los datos de envío del formulario
            fd.delete('balance');
            fd.delete('estado_de_resultados');
            fd.delete('carpeta_tributaria');
            fd.delete('otro_documento');
            this.enviar_documento(documentos)
          }else{
            this.$swal({
              icon: 'info',
              title: 'Balance y Estado de resultados son obligatorios.',
            })
          }
        }
      });
    },
    async enviar_documento(documentos){
      let token = this.$cookies.get('token');
      var queryDocumento = ''
      let html_res = ''
      for (let i = 0; i < documentos.length ; i++ ) {
        if(documentos[i].value !== ''){
          let data_name = documentos[i].dataset.name;
          let input_name = documentos[i].name;
          let data_file = documentos[i].files[0];
          let file_name = documentos[i].files[0].name;
          let comentario = document.getElementById('observacionForm').value
          this.$swal({
            title: 'Ingresando documento ',
            text:'Subiendo '+data_name,
            allowOutsideClick: false,
            didOpen: () => {
                this.$swal.showLoading()
            },
          })
          fd.set(input_name, data_file,file_name);          
          
          var byteFile = await getAsByteArray(data_file)
          //esto se comenta por el momento byteFile = String.fromCharCode.apply(null, byteFile)
          let data = await this.subir_documento_axio(token,queryDocumento,{
            "documento": byteFile,
            'nombre' : file_name,
            "comentario": comentario,
            "doc" : input_name
          })
          if (data.data.status === 'success') {
              if(queryDocumento === ''){
                queryDocumento = '&doc=' + data.data.data.id_documentos
              }
            html_res += `<p><img src="/icon/check_success.png"  width="20" height="20"> ${data_name} subido correctamente</p>`;
          } else {
              // llega un error, marca error
              html_res += `<p><img src="/icon/warning.png"  width="20" height="20"> Error al subir ${data_name}</p>`;
          }
          //elimina el documento para subir
          fd.delete(input_name);
        }
    }
    let timerInterval
    this.$swal({
      icon: 'info',
      title: 'Información de ingreso de documentos',
      html: html_res,
      timer: 5000,
      timerProgressBar: true,
      footer:"En unos segundos serás redireccionado.",
      didOpen: () => {
          this.$swal.showLoading()
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === this.$swal.DismissReason.timer) {
        this.$router.push('MisDocumentos')
      }
    })
    setTimeout(()=>{
         this.$router.push('MisDocumentos')
    },9000);

    //fin
  },
  async subir_documento_axio(token,queryDocumento,documento){
    var response;
  
    await axios.post(process.env.VUE_APP_API_URL_BASE +'api/cliente/subir-un-documento?token=' +token+ queryDocumento, documento,{
        headers:{
          'Content-Type': 'application/json'
        }
      }).
    then((data)=>{
          response = data
    }).catch(() => {
    });
    return response
  }
  //fin funciones
  },
};



function readFile(file) {
  return new Promise((resolve, reject) => {
    // Create file reader
    let reader = new FileReader()

    // Register event listeners
    reader.addEventListener("loadend", e => resolve(e.target.result))
    reader.addEventListener("error", reject)

    //antes se intento desde un bytes y se uso esta función reader.readAsBinaryString(file)
    // Read file
    reader.readAsDataURL(file)
  })
}

async function getAsByteArray(file) {
  return (await readFile(file))
}



</script>
